@font-face {
    font-family: "Neon";
    src: url("../fonts/Neon\ Future.ttf");
}

@font-face {
    font-family: "Astral";
    src: url("../fonts/Astral\ Delight\ Upright.ttf");
}

:root {
  --primary-color: #05d9e8;
  --secondary-color: #ff2a6a;
  --hover-color: #999999;
  --bg-color: whitesmoke;
  --brand-color: #01012b;
  --header-color:  #005678;
  --card-color: black;
  --header-font-color: #05d9e8;
}

[data-theme="dark"] {
  --primary-color: #05d9e8;
  --secondary-color: #ff2a6a;
  --hover-color:  #ff2a6a;
  --bg-color: black;
  --brand-color: black;
  --header-color:  black;
  --card-color: ff2a6a;
  --header-font-color: #05d9e8;
}

ul {
    list-style-type: none;
}

a {
  text-decoration: none;
}

.a_card {
  font-family: "Neon";
  font-size: .75rem;
  color: var(--primary-color);
  margin: 8 8 8 8px;
}

.a_special {
    font-family: "Neon";
    font-size: 1.2vh;
    color: var(--header-font-color);
    padding: 10px;
}

.a_special:active a {
    color: var(--header-font-color);
}

.a_special:hover  {
  cursor: pointer;
  color: var(--hover-color);
}

.brand {
   color: var(--primary-color);
   float: right;
}

.brand:hover {
  transition: 0.2s;
  cursor: pointer;
  background: var(--brand-color);
  color:  var(--secondary-color);
}

.body {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
  background:whitesmoke;
}

.header {
  background: var(--header-color);
  padding: 1px;
  font-family: "Astral";
  color: var(--primary-color);
  font-size: .17rem;
  display: grid;
  place-items: center;
}

.header_links {
  place-items: center;
  background: var(--header-color);
  grid-template-columns: repeat(8, 1fr);
}

.header_links:hover {
  transition: 0.2s;
  cursor: pointer;
  color:  var(--secondary-color);
}

.change_header {
  place-items: center;
  background: var(--header-color);
  padding: 1px;
  font-family: "Astral";
  color: var(--primary-color);
  font-size: 3vh;
}

.change_header:hover {
  color:  var(--secondary-color);
  cursor: default;
}

.inline_vote {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.colRight {
  float: right;
}

.colLeft {
  float: left;
}

.hero {
  display: grid;
  grid-gap: .5rem;
  background: var(--secondary-color);
  margin: .25rem 0;
  font-family: "Neon";
  color: var(--primary-color);
   font-size: .5rem;
  margin-bottom: 1rem;
}

.main {
  display: grid;
  grid-gap: .25rem;
  background: var(--secondary-color);
  grid-template-columns: repeat(1, 2fr);
  font-family: "Neon";
  color: var(--secondary-color);
}

.visual {
  width: 100%;
  color: var(--secondary-color);
  background: var(--bg-color);
  margin: 0.25rem 0;
}

.visual_dark {
    width: 100%;
    display: inline-block;
    font-size: .75rem;
    justify-content: center;
    align-items: center;
}

.visual_dark:hover {
  cursor: pointer;
  background: var(--primary-color);
  color:  var(--secondary-color);
}

.card {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  background: var(--bg-color);
  color: var(--primary-color);
  padding: 1px;
  margin-bottom: 1px;
}

.card_list {
  background: var(--bg-color);
  border: 1px solid var(--bg-color);
  justify-content: center;
  margin: 1rem;
  margin-right: 3rem;
  padding: 1px;
  border-style: outset;
}

.card_list:active {
  border-style: inset;
}

.card_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--bg-color);
  color: var(--primary-color);
  padding: 15px;
  font-size: .75rem;
  grid-gap: 0px;
  cursor: pointer;
  border-style: outset;
  border-width: 1px;
  border-radius: 1%;
  transition: all 0.6s;
}

.card_title {
  font-family: "Roboto";
}

.card_font {
  font-family: "Roboto";
  color: var(--card-color);
}

.card_title {
  grid-template-columns: repeat(2, 1fr);
}

.main_title {
  display: grid;
  place-items: center;
   color: var(--primary-color);
  margin-top: 1px;
  margin-bottom: 1px;
}

.main_title:hover {
  transition: 0.2s;
  cursor: pointer;
  color:  var(--secondary-color);
}

.game_over {
  display: grid;
  place-items: center;
  font-size: 10rem;
   color:  var(--secondary-color);
   float: right;
}
.game_over2 {
  display: grid;
  place-items: center;
  font-size: 3rem;
   color: var(--primary-color);
   float: right;
}

.highscore_message {
  display: grid;
  place-items: center;
  font-size: 3rem;
   color:  var(--secondary-color);
   float: right;
}

.game_over:hover {
  transition: 0.2s;
  cursor: pointer;
  color:  var(--secondary-color);
}

.footer {
  background: var(--header-color);
  padding: 1px;
  color: var(--primary-color);
  text-align: center;
  font-size: 1rem;
  font-family: "Astral";
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: vh;
}

body {
  font-family: system-ui, sans-serif;
}

.profile_pic {
  height:250px;
  border-radius: 50%;
  border: 10px solid var(--secondary-color);
}

.profile_pic:hover {
  opacity: 1;
  height: 300px;
  transition: 0.3s;
  cursor: pointer;
}

/*Simple css to style it like a toggle switch*/
.theme-switch-wrapper {
  display: flex;

}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: whitesmoke;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

input:checked + .slider {
  background-color: var(--secondary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}