@font-face {
    font-family: "Neon";
    src: url("../fonts/Neon\ Future.ttf");
}

@font-face {
    font-family: "Astral";
    src: url("../fonts/Astral\ Delight\ Upright.ttf");
}

.Kodi {
  width: 80%;
  height: 80%;
  position: relative;
  margin-left: .5rem;
  margin-top: .2rem;
}

.toggle {
  margin-left: 10px;
  margin-right: 10px;
}

.button {
  font-size: 1em;
  color: #ff2a6a;
  background-color: #05d9e8;;
  font-family: "Neon";
  cursor: pointer;
}

#switches {
  position: absolute;
  font-family: 'Neon';
  margin-left: -1.3rem;
  margin-top: 6rem;
  cursor: pointer;
}

.text2 {
  position: absolute;
  color: #05d9e8;
  font-family: 'Neon';
  font-size: 3rem;
  margin-left: 1rem;
  margin-top: 28rem;
}

.userName {
  position: absolute;
  color: #ff2a6a;
  font-family: 'Neon';
  font-size: .5rem;
  margin-left: -11rem;
  margin-top:1rem;
}

.text1 {
  position: absolute;
  font-family: 'Neon';
  color:#05d9e8;
  font-size: .6rem;
  margin-left: -3em;
  margin-top: 5rem;
}

.text1 span:last-child {
  padding-left: 70px;
}

#title {
  font-family: 'Roboto';
  position: absolute;
  font-size: 1.8rem;
  margin-left: 35px;
  margin-top: 40px;
}

#turn {
  position: absolute;
  background: white;
  color: #ff2a6a;
  font-family: "Neon";
  font-size: 2rem;
  margin-left: 5.2rem;
  margin-top: -3rem;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}

#outer-circle {
  background: #05d9e8;
  border-radius: 50%;
  height:150px;
  width: 150px;
  position: relative;
  border-style: solid;
  border-width: 5px;
  margin: auto;
  margin-top: 0px;
  box-shadow: 8px 8px 15px 5px #888888;
}

#topleft {
  position: absolute;
  height: 125px;
  width: 125px;
  border-radius: 125px 0 0 0;
  -moz-border-radius: 125px 0 0 0;
  -webkit-border-radius: 125px 0 0 0;
  background: darkgreen;
  top: 50%;
  left: 50%;
  margin: -125px 0px 0px -125px;
  border-style: solid;
  border-width: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#topright {
  position: absolute;
  height: 125px;
  width: 125px;
  border-radius: 0 125px 0 0;
  -moz-border-radius: 0 125px 0 0;
  -webkit-border-radius: 0 125px 0 0;
  background: darkred;
  top: 50%;
  left: 50%;
  margin: -125px 0px 0px 0px;
  border-style: solid;
  border-width: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#bottomleft {
  position: absolute;
  height: 125px;
  width: 125px;
  border-radius: 0 0 0 125px;
  -moz-border-radius: 0 0 0 125px;
  -webkit-border-radius: 0 0 0 125px;
  background: goldenrod;
  top: 50%;
  left: 50%;
  margin: 0px -125px 0px -125px;
  border-style: solid;
  border-width: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#bottomright {
  position: absolute;
  height: 125px;
  width: 125px;
  border-radius: 0 0 125px 0;
  -moz-border-radius: 0 0 125px 0;
  -webkit-border-radius: 0 0 125px 0;
  background: darkblue;
  top: 50%;
  left: 50%;
  margin: 0px 0px -125px 0px;
  border-style: solid;
  border-width: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#inner-circle {
  position: absolute;
  background: whitesmoke;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  border-style: solid;
  border-width: 10px;
  top: 50%;
  left: 50%;
  margin: -62.5px 0px 0px -62.5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}